import { useEffect, useState, useMemo, useCallback } from 'react';
import { getCityById } from '../api/services/cities-service';

export function findNameById(dataArray, id, propertyName = 'Name') {
    const foundItem = dataArray?.find((value) => value?.Id === id);
    return foundItem ? foundItem[propertyName] : '';
}

function findCodeById(dataArray, id) {
    return dataArray?.find((item) => item.Id === id)?.Code ?? '';
}

function useFindName(
    differences,
    organizationForms,
    counties,
    organizationTypes,
    companyTypes,
    CAENCodes
) {
    const [cityName, setCityName] = useState('');

    const fetchCityName = useCallback(async () => {
        if (differences.CityId || differences.LocalityId) {
            const resolvedCity = await getCityById(differences.CityId || differences.LocalityId);
            setCityName(resolvedCity?.Name ?? '');
        }
    }, [differences.CityId, differences.LocalityId]);

    useEffect(() => {
        fetchCityName();
    }, [fetchCityName]);

    const updateDifferences = useMemo(() => {
        if (!differences || differences.length === 0) return {};

        return {
            ...differences,
            PersonTypeId: findNameById(organizationForms, differences.PersonTypeId),
            CountyId: findNameById(counties, differences.CountyId),
            CityId: cityName,
            OrganizationTypeId: findNameById(organizationTypes, differences.OrganizationTypeId),
            CompanyTypeId: findNameById(companyTypes, differences.CompanyTypeId),
            MainCaenCodeId: findCodeById(CAENCodes, differences.MainCaenCodeId),
            SecondaryCaenCodeId1: findCodeById(CAENCodes, differences.SecondaryCaenCodeId1),
            SecondaryCaenCodeId2: findCodeById(CAENCodes, differences.SecondaryCaenCodeId2),
            LocalityId: cityName,
        };
    }, [
        differences,
        organizationForms,
        counties,
        organizationTypes,
        companyTypes,
        CAENCodes,
        cityName,
    ]);

    const nonEmptyUpdateDifferences = useMemo(() => {
        return Object.fromEntries(
            Object.entries(updateDifferences).filter(([_, value]) => value !== '')
        );
    }, [updateDifferences]);

    return { updateDifferences: nonEmptyUpdateDifferences };
}

export default useFindName;
