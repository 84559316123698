export const BANKS = 'banks';
export const CUSTOMER = 'customer';
export const CURRENCY = 'currency';
export const FINANCING_SOURCE = 'financingSource';
export const WARRANTY_TYPE = 'warrantyType';
export const TYPE_PERSON = 'typePerson';
export const CEILING = 'ceiling';
export const CREDIT_TYPE = 'creditType';
export const FINANCING_TYPE = 'financingType';
export const DURATION_TYPE = 'durationType';
export const CONVENTION = 'convention';
export const AFIR_MEASURES = 'afirMeasures';
export const BENEFICIARY_COUNTY = 'beneficiaryCounty';
export const GUARANTEE_STATUS = 'guaranteeStatus';
export const BANK_AGENCY = 'bankAgency';
export const APPROVAL_GUARANTEE_COMPETENCE = 'approvalGuaranteeCompetence';
export const COMPANY = 'company';
export const COUNTIES = 'counties';
export const ORGANIZATION_TYPES = 'organizationTypes';
export const WARRANTY_REAL_TYPE = 'warrantyRealType';
export const SYNTHETIC_ACCOUNT = 'syntheticAccount';
export const ANALYTICAL_ACCOUNT = 'analyticalAccount';
export const TEMPLATE_TYPE = 'templateType';
export const DATA_SOURCE = 'DataSource';
export const DATA_SOURCE_LOWER = 'dataSource';
export const ACCOUNTING_STATUS = 'accountingStatus';
export const SOURCE_BY_ID = 'sourceById';
export const CONVENTION_BY_ID = 'conventionById';
export const WARRANTY_NEW_FIELD = 'warrantyNewField';
export const CURRENCY_BY_ID = 'currencyById';
export const RISK_CATEGORY = 'riskCategory';
export const CRC_STATUS = 'crcStatus';
export const DEBTOR = 'debtor';
export const BNR_EXPOSURE = 'bnrExposure';
export const SS_CODE = 'ssCode';
export const CAEN_CODES = 'caenCodes';
export const FIELD_OF_ACTIVITY = 'fieldOfActivity';
export const COMPANY_TYPE = 'companyType';
export const BANKS_AGENCY_BY_ID = 'banksAgencyById';
export const BANK_TYPE = 'bankType';
export const CURRENT_USER = 'currentUser';
export const USER_BY_ID = 'userById';

//fetchSingleDataSource
export const TEAM_BANK = 'teamBank';

//FUTURE CONNECT
export const QUALITY_REPRESENTATIV = 'qualityRepresentativ';
export const BANK_RATING = 'bankRating';
export const CITIES = 'cities';
export const TEMPLATE = 'template';
export const USERS = 'users';
export const SOURCE = 'source';
export const RATING = 'rating';

//no cache

export const noCache = [
    CURRENT_USER,
    USER_BY_ID,
    BANKS_AGENCY_BY_ID,
    TYPE_PERSON,
    SOURCE_BY_ID,
    CONVENTION_BY_ID,
    WARRANTY_NEW_FIELD,
    DATA_SOURCE,
    DATA_SOURCE_LOWER,
    CITIES,
    BANKS,
    BANK_AGENCY,
    COMPANY,
    BANK_RATING,
    TEMPLATE,
    USERS,
    SOURCE,
    RATING,
    CONVENTION,
    CURRENCY_BY_ID,
    CEILING,
];
