import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { fetchWrapper } from '../../utils/fetch-wrapper';
import { isNotEmpty } from '../../utils/utils';

export async function get(id) {
    if (!id) {
        return;
    }

    let response = await fetchWrapper('/api/cities?countyId=' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

export async function getCityById(id) {
    if (!id) {
        return '';
    }

    let response = await fetchWrapper(`/api/cities/get-by-id?cityId=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

let CountyId = 0;
let abortController = new AbortController();
const cache = new Map();
let debounceTimeout;

export function getCitiesDataSource() {
    let store = new CustomStore({
        key: 'Id',
        load: async (loadOptions) => {
            let params = '';
            ['skip', 'take', 'filter'].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });

            params = params.slice(0, -1);
            if (isNotEmpty(loadOptions.searchExpr) && isNotEmpty(loadOptions.searchValue)) {
                params += `&filter=["${loadOptions.searchExpr}","${loadOptions.searchOperation}", "${loadOptions.searchValue}"]`;
            }

            CountyId = loadOptions.userData.CountyId ?? CountyId;
            let response = await fetchWrapper(`/api/cities?countyId=${CountyId}&${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.json();
        },
        byKey: async (key) => {
            if (cache.has(key)) {
                return cache.get(key);
            }

            clearTimeout(debounceTimeout);
            return new Promise((resolve, reject) => {
                debounceTimeout = setTimeout(async () => {
                    abortController.abort();
                    abortController = new AbortController();
                    const { signal } = abortController;
                    try {
                        let response = await fetchWrapper(`/api/cities/get-by-id?cityId=${key}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            signal,
                        });

                        const result = await response.json();
                        cache.set(key, result); // Cache the result
                        resolve(result);
                    } catch (error) {
                        if (error.name === 'AbortError') {
                            resolve(); // Do nothing on abort
                        } else {
                            console.error('Error fetching data by key:', error);
                            reject(error);
                        }
                    }
                }, 500);
            });
        },
    });

    return new DataSource({
        store: store,
        paginate: true,
        pageSize: 10,
    });
}
