import {
    ERROR_ACTION_BTN,
    ERROR_MESSAGES,
    STRING_ERROR_SAVE,
    SUCCES_ACTION_BTN,
} from '../constants/constMessages';
import { fetchWrapper } from './fetch-wrapper';
import { getBaseUrl } from './getBaseUrl';
import { notifyErrorFetchData, notifyGeneralError, notifyGeneralSucces } from './utilsForGrid';

export async function handleRequest(url, method, data = null) {
    const options = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    try {
        const response = await fetchWrapper(url, options);
        if (!response.ok && method === 'GET') {
            const errorData = await response.json();
            throw new Error(
                `Eroare: ${response.status} - ${errorData.message || 'O eroare a survenit!'}`
            );
        }
        if (method === 'GET') {
            return response.status === 204 ? [] : response.json();
        }
        return response;
    } catch (error) {
        console.error(`Request failed: ${error.message}`);
        notifyErrorFetchData();
        return;
    }
}

export const handleErrorResponse = async (response, defaultMessage = STRING_ERROR_SAVE) => {
    if (!response?.ok) {
        let errorMessage = '';

        if (typeof response === 'string') {
            errorMessage = response;
            throw new Error(errorMessage);
        }

        if (response?.detail) {
            errorMessage = response?.detail;
            throw new Error(errorMessage);
        }
        const errDetails = await response?.json();
        if (errDetails?.detail) {
            errorMessage = errDetails?.detail;
        } else {
            errorMessage = ERROR_MESSAGES[response?.status] || defaultMessage;
        }

        throw new Error(errorMessage);
    }
};

export const withAsync = async (fn, succesMessage = SUCCES_ACTION_BTN, withData = false) => {
    if (typeof fn !== 'function') {
        throw new Error('The argument must be a function.');
    }

    try {
        const response = await fn();
        if (response.ok) {
            if (withData) {
                const responseJson = await response.json();
                notifyGeneralSucces(succesMessage);
                return {
                    ok: true,
                    data: responseJson,
                };
            }
            notifyGeneralSucces(succesMessage);
            return { ok: true };
        }

        await handleErrorResponse(response, ERROR_ACTION_BTN);
    } catch (error) {
        notifyGeneralError(error.message);
        return {
            ok: false,
            error: error.message,
        };
    }
};

export const handleOutsideRequest = async ({ url, method, data = null }) => {
    const options = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    const response = await fetch(getBaseUrl() + url, {
        ...options,
    });

    return response;
};
