import React, { createContext, useState } from 'react';

const dataContext = () => {
    const FormContext = createContext();

    const useCustomFormHook = (initialState = {}) => {
        const [formData, setFormData] = useState({ ...initialState });
        return { formData, setFormData };
    };

    const StoreDataProvider = ({ children }) => {
        const { formData, setFormData } = useCustomFormHook();

        return (
            <FormContext.Provider value={{ formData, setFormData }}>
                {children}
            </FormContext.Provider>
        );
    };

    const useFormContext = () => {
        const context = React.useContext(FormContext);
        if (!context) {
            throw new Error('useFormContext must be used within a StoreDataProvider');
        }
        return context;
    };

    return { StoreDataProvider, useFormContext };
};

export const { StoreDataProvider, useFormContext } = dataContext();
